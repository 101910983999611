import {
    StandardMaterial, VideoTexture, Color3, Vector3, Mesh,
    // Vector4,
    // MeshBuilder,
} from '@babylonjs/core';

import { visibleInInspector } from "./decorators";

export default class VideoWall extends Mesh {

    @visibleInInspector("string", "Video URL", '')
    private _vurl: string;

    // TWEAKS|TODO: GLTF-imported double sided planes do not work well..
    @visibleInInspector("boolean", "Double Side", true)
    private _doubleSide: boolean;
    @visibleInInspector("boolean", "Use Z Offset", false)
    private _zOffset: boolean;
    @visibleInInspector("number", "Offset Amount", 0.1)
    private _amount: number;

    /**
     * Override constructor.
     * @warn do not fill.
     */
    // @ts-ignore ignoring the super call as we don't want to re-init
    protected constructor() { }

    /**
     * Called on the node is being initialized.
     * This function is called immediatly after the constructor has been called.
     */
    public onInitialize(): void {
        // ...
    }

    /**
     * Called on the scene starts.
     */
    public onStart(): void {
        // const planeOpts = {
        //     height: 5.4762,
        //     width: 7.3967,
        //     sideOrientation: Mesh.DOUBLESIDE
        // };
        // const vPlane: Mesh = MeshBuilder.CreatePlane("plane", planeOpts, this._scene);
        // vPlane.parent = this.parent;
        // vPlane.position = this.position;

        // TWEAKS|TODO: GLTF-imported double sided planes do not work well..
        // const fp: Mesh = this.clone(`${this.name}_flip`);
        // fp.translate(new Vector3(this._zOffset ? 0 : 1, 0, this._zOffset ? 1 : 0), this._amount);
        // fp.rotate(new Vector3(0, 1, 0), 180 * (Math.PI / 180));

        const VideoMat: StandardMaterial = new StandardMaterial("m", this._scene);
        let VideoSource: string = this._vurl ? this._vurl : '_streamingAssets/003.mp4';

        // if (
        //     /ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) &&
        //         'ontouchend' in document) {
        //     VideoSource = VideoSource.replace(/(.*)\.webm$/, '$1.mp4');
        // }

        // if (!MediaSource.isTypeSupported('video/webm; codecs="vp9, vorbis"')) {
        //     VideoSource = VideoSource.replace(/(.*)\.webm$/, '$1.mp4');
        // }

        // TODO: force to use mp4
        VideoSource = VideoSource.replace(/(.*)\.webm$/, '$1.mp4');

        const VideoTex: VideoTexture = new VideoTexture(
            `${this.name}_videotex`,
            VideoSource,
            this._scene
        );

        VideoTex.wAng = 90 * (Math.PI / 180);
        VideoMat.diffuseTexture = VideoTex;
        VideoMat.roughness = 1;
        VideoMat.emissiveColor = Color3.White();
        VideoMat.backFaceCulling = false;

        this.overrideMaterialSideOrientation = Mesh.DOUBLESIDE;
        this.material = VideoMat;

        // TWEAKS|TODO: GLTF-imported double sided planes do not work well..
        // if (this._doubleSide) {
        //     fp.overrideMaterialSideOrientation = Mesh.FRONTSIDE;
        //     fp.material = VideoMat;
        // }

        VideoTex.video.play();
    }

    /**
     * Called each frame.
     */
    public onUpdate(): void {
        // ...
    }

    /**
     * Called on the object has been disposed.
     * Object can be disposed manually or when the editor stops running the scene.
     */
    public onStop(): void {
        // ...
    }

    /**
     * Called on a message has been received and sent from a graph.
     * @param message defines the name of the message sent from the graph.
     * @param data defines the data sent in the message.
     * @param sender defines the reference to the graph class that sent the message.
     */
    public onMessage(name: string, data: any, sender: any): void {
        switch (name) {
            case "myMessage":
                // Do something...
                break;
        }
    }
}
