import {
    Engine,
    TransformNode,
    Sound,
    Mesh,
    ArcRotateCamera,
} from '@babylonjs/core';

import { visibleInInspector, fromScene } from "./decorators";

import PlayerCamera from './player';

export default class Sepaker extends TransformNode {
    @visibleInInspector("string", "Audio File URL", '')
    private _aurl: string;

    @fromScene('segment_b')
    private _segment_b: Mesh;
    @fromScene('segment_c')
    private _segment_c: Mesh;
    @fromScene('segment_d')
    private _segment_d: Mesh
    @fromScene('segment_e')
    private _segment_e: Mesh;
    @fromScene('segment_f')
    private _segment_f: Mesh;

    private _snd_fragments: Object = {};
    private _playerMesh: Mesh;
    private _birdCamera: ArcRotateCamera

    /**
     * Override constructor.
     * @warn do not fill.
     */
    // @ts-ignore ignoring the super call as we don't want to re-init
    protected constructor() { }

    /**
     * Called on the node is being initialized.
     * This function is called immediatly after the constructor has been called.
     */
    public onInitialize(): void {
        // ...
    }

    /**
     * Called on the scene starts.
     */
    public onStart(): void {
        this._playerMesh = this._scene.getMeshByName('tracker') as Mesh;
        this._birdCamera = this._scene.getCameraByName('birdCamera') as ArcRotateCamera;

        this._snd_fragments[`room_sound`] = new Sound(
            `${this.name}_audio_room`,
            this._aurl ? this._aurl : '_streamingAssets/sampleA.mp3',
            this._scene,
            null,
            {
                loop: true,
                autoplay: false,
                spatialSound: false,
                // distanceModel: 'exponential',
                // rolloffFactor: 2
            }
        );
        this._snd_fragments[`room_sound`].setPosition(this.position);

        this._snd_fragments[`transition_sound`] = new Sound(
            `${this.name}_audio_transition`,
            this._aurl ? this._aurl : '_streamingAssets/sampleG.mp3',
            this._scene,
            null,
            {
                loop: false,
                autoplay: false,
                spatialSound: false,
            }
        );

        // TODO:
        const segments = [
            this._segment_b,
            this._segment_c,
            this._segment_d,
            this._segment_e,
            this._segment_f,
        ]

        for (let el of segments) {
            const mp3_name: string = el.name.split('_').map((s, i, a) => i == a.length - 1 ? s.toUpperCase() : 'sample').join('');

            this._snd_fragments[el.name] = new Sound(
                `${this.name}_audio_${el.name}`,
                `_streamingAssets/${mp3_name}.mp3`,
                this._scene,
                null,
                {
                    loop: true,
                    autoplay: false,
                    spatialSound: false,
                    volume: 0
                    // useCustomAttenuation: true
                }
            );
            // this._snd_fragments[el.name].attachToMesh(el);
        }
    }

    /**
     * Called each frame.
     */
    public onUpdate(): void {
        // ...
        // TODO:
        [
            this._segment_b,
            this._segment_c,
            this._segment_d,
            this._segment_e,
            this._segment_f,
        ].forEach((seg) => {
            if (this._scene.activeCamera != this._birdCamera && this._playerMesh && seg.intersectsMesh(this._playerMesh)) {
                this._snd_fragments[seg.name].setVolume(0.7);

            } else {
                this._snd_fragments[seg.name].setVolume(0);
            }
        });
    }

    /**
     * Called on the object has been disposed.
     * Object can be disposed manually or when the editor stops running the scene.
     */
    public onStop(): void {
        // ...
    }

    public unlockAndPlayAll(): void {
        // Object.keys(this._snd_fragments).forEach((k: string) => {
        //     console.log(k, this._snd_fragments[k].isPlaying);
        // });

        Engine.audioEngine.unlock()

        Object.values(this._snd_fragments).forEach((s: Sound) => {
            if (!s.isPlaying) {
                s.autoplay = true;
                s.play();
            }
        });

        console.info('unlocked audio');
        Object.keys(this._snd_fragments).forEach((k: string) => {
            console.log(k, this._snd_fragments[k].isPlaying);
        });
    }
}
